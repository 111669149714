import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomToast = ({ message }) => {
    return (
        <div>
            <div>{message}</div>
        </div>
    );
};

export const showToast = (message, options = {}) => {
    options={...options,
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
    }
    toast(message, options);
};
