import { Typography, Theme, styled } from '@mui/material';
import { COLOR_DARK, COLOR_TEXT } from '../../constants/color';
interface PublicPageSmallTextProps {
    onClick?: () => void;
    theme?: Theme;
    text: string;
}

export const PublicPageSmallText: React.FC<PublicPageSmallTextProps> = ({ text, sx }) => {
    const StyledPublicPageSmallText = styled(Typography)(({ theme }: { theme: Theme }) => ({
        fontFamily: 'Poppins',
        fontSize: '0.825rem',
        fontWeight: 400,
        fontStyle: 'normal',
        letterSpacing: '0.02rem',
        wordSpacing: '14%',
        lineHeight: '140%',
        color: COLOR_DARK,
        textAlign: 'justify',
        margin: theme.spacing(1, 0, 2, 0),
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.825rem',
            textAlign: 'justify',
        },
    }));
    return (
        <StyledPublicPageSmallText sx={sx} variant="body1">
            {text}
        </StyledPublicPageSmallText>
    )
};

