import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { scholarshipAppliedAPI } from "../../services/api/scholarships";

const initialState = {
    scholarshipAppliedFetched: false,
    scholarshipAppliedFetching: false,
    scholarshipApplied: {},
    errorMessage: '',
}

export const scholarshipAppliedList = createAsyncThunk(
    'scholarshipApplied',
    async ({body, token}, { rejectWithValue }) =>
    {
        try
        {
            const result = await scholarshipAppliedAPI(body, token);
            return result
        } catch (err)
        {
            return rejectWithValue(err)
        }
    }
)

const scholarshipAppliedSlice = createSlice({
    name: "scholarshipApplied",
    initialState,
    reducers: {
    },
    extraReducers: (sub) =>
    {
        sub.addCase(scholarshipAppliedList.pending, (state) =>
        {
            state.scholarshipAppliedFetching = true;
        });

        sub.addCase(scholarshipAppliedList.fulfilled, (state, action) =>
        {
            state.scholarshipAppliedFetched = true;
            state.scholarshipAppliedFetching = false;
            state.scholarshipApplied = action.payload;
        });

        sub.addCase(scholarshipAppliedList.rejected, (state, action) =>
        {
            state.scholarshipAppliedFetching = false;
            state.errorMessage = action.payload;
        });
    },

})

export default scholarshipAppliedSlice.reducer