import React, { useEffect } from 'react';
import { showToast } from '../../Components/Dashboard/common/Toast/index'
import { useNavigate } from 'react-router-dom';
import { logout } from '../../store/auth/LoginSlice';
import { getUserLogoutURL } from '../../services/api/endpoints';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';


 const  UnAuthorizedPage =() => {
    const auth_token = useSelector((state: any) => state.login.loginDetails.data.auth_token);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleLogout = async() => {
        try{
            const res = await axios.post(`${getUserLogoutURL}`,{}, {
                headers: {
                    'Authorization': `Bearer ${auth_token}`,
                  },
            });
            if(res && res.data.code === 200){
                localStorage.clear();
                dispatch(logout())
                .then(() => {

                })
                .catch((error) => {
                })

            } else{
                localStorage.clear();
            }
        }
        catch(error){
            localStorage.clear();
        }

    };

    useEffect(() => {
        handleLogout();
        showToast(`Unauthorized Access. Please login again.`, {
             
             
             
             
            type: 'error'
          });
        navigate('/users/login');
    }, []);
    return null;
}

export default UnAuthorizedPage;



