import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './auth/LoginSlice';
import statReducer from './student/ScholarshipStatSlice';
import userActivityReducer from './student/UserActivitySlice';
import scholarshipFeaturedReducer from './student/ScholarshipFeaturedSlice';
import scholarshipAppliedReducer from './student/ScholarshipAppliedSlice';
import suggestedStateReducer from './student/SuggestedStateSlice';
import notificationListReducer from './notifications/NotificationListSlice';
import notificationCountReducer from './notifications/NotificationCountSlice';
import scholarshipCountAmountReducer from './student/ScholarshipCountAmountSlice';
import sidebarStatusSliceReducer from './sidebar/sideBarStatus';
import staticDataReducer from './static/StaticDataSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import userDetailsReducer from './userAccountDetails/StudentDetailsSlice';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION__?: () => any;
    }
}

const rootReducer = combineReducers({
    login: loginReducer,
    stat: statReducer,
    scholarshipFeatured: scholarshipFeaturedReducer,
    scholarshipApplied: scholarshipAppliedReducer,
    suggestedState: suggestedStateReducer,
    notificationList: notificationListReducer,
    notificationCount: notificationCountReducer,
    userActivity: userActivityReducer,
    scholarshipCountAmount: scholarshipCountAmountReducer,
    sideBar: sidebarStatusSliceReducer,
    staticData: staticDataReducer,
    userDetails: userDetailsReducer
});

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production' ? (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__() : false,
    middleware: [thunk],
});

export const persistor = persistStore(store);
