import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { scholarshipStatsAPI } from "../../services/api/scholarships";


const initialState = {
    statFetched: false,
    statFetching: false,
    stat: {},
    errorMessage: '',
}

export const statList = createAsyncThunk(
    'stat',
    async (token, { rejectWithValue}) => {
        try {
            const result = await scholarshipStatsAPI(token)
            return result
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

const statSlice = createSlice({
    name: "stat",
    initialState,
    reducers: {
        resetStat: state => {
            // Reset state to initial values
            return initialState;
        },
    },
    extraReducers: (sub) => {
        sub.addCase(statList.pending, (state) => {
            state.statFetching = true;
        });

        sub.addCase(statList.fulfilled, (state, action) => {
            state.statFetched = true;
            state.statFetching = false;
            state.stat = action.payload;
        });

        sub.addCase(statList.rejected, (state, action) => {
            state.statFetching = false;
            state.errorMessage = action.payload;
        });
        // Add case for resetting the state on logout
        sub.addCase('user/logout', (state) => {
            return initialState;
        })
    },

})

export const { resetStat } = statSlice.actions;

export default statSlice.reducer

