import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import CircularProgress from '@mui/material/CircularProgress'
import { lazyWithPreload } from '../services/prefetch/prefetch.ts';
import NewPasswordPage from '../pages/RecoverPassword/NewPasswordPage.tsx';
import { useSelector } from 'react-redux';
const NoPageFound = lazyWithPreload(() => import('../pages/NotFoundPage/NotFoundPage.tsx'));
const EmbeddedScholarhips = lazyWithPreload(() => import('../pages/CounselorDashboard/ListManger/ScholarshipPage/Embed/index.tsx'));
const ViewPublicScholarships = lazyWithPreload(() => import('../pages/CounselorDashboard/ListManger/ScholarshipPage/Embed/PublicScholarshipView/index.tsx'));
import { LoadingFallback } from '../Components/LoadingFallback/LoadingFallback.tsx';

const ExternalRoutes = () => {
    const loginDataFromRedux = useSelector((state) => state?.login);
    const { loginDetails } = loginDataFromRedux;
    const userRole = loginDetails?.data?.user?.usersRoles?.[0]?.role?.normalizedName;
    return (
        <Routes>
        <Route path="/:organizationIdOrName/scholarships" element={<React.Suspense fallback={<LoadingFallback/>}><EmbeddedScholarhips /></React.Suspense>}></Route>
        <Route path="/:organizationIdOrName/scholarships/:scholarshipId" element={<React.Suspense fallback={<LoadingFallback/>}><ViewPublicScholarships /></React.Suspense>}></Route>
        <Route path="*" element={<NoPageFound />} />
      </Routes>
)
};

export default ExternalRoutes;