import qaConfig from './config.qa.json';
import devConfig from './config.development.json';
import prodConfig from './config.production.json';


let envVariables: Record<string, string> = {};
switch (process.env.NODE_ENV) {
  case 'qa':
    envVariables = qaConfig;
    break;
  case 'production':
    envVariables = prodConfig;
    break;
  default:
    envVariables = devConfig;
    break;
}
export const AMAZON_S3_BASE_URL = envVariables.AMAZON_S3_BASE_URL;
export const DOMAIN = envVariables.DOMAIN;
export const HOME = envVariables.HOME;
export const CONTENTFUL_BASE_URL = envVariables.CONTENTFUL_BASE_URL;
export const CONTENTFUL_SPACE_ID = envVariables.CONTENTFUL_SPACE_ID;
export const CONTENTFUL_ENVIRONMENT_ID = envVariables.CONTENTFUL_ENVIRONMENT_ID;
export const CONTENTFUL_ACCESS_TOKEN = envVariables.CONTENTFUL_ACCESS_TOKEN;
export const RECAPTCHA_SITE_KEY = envVariables.RECAPTCHA_SITE_KEY;
export const AMAZON_S3_ACCESS_KEY = envVariables.AMAZON_S3_ACCESS_KEY;
export const AMAZON_S3_SECRET_KEY = envVariables.AMAZON_S3_SECRET_KEY;
export const AMAZON_S3_BUCKET_NAME = envVariables.AMAZON_S3_BUCKET_NAME;
export const GOOGLE_ANALYTICS_TRACKING_ID = envVariables.GOOGLE_ANALYTICS_TRACKING_ID;
export const GOOGLE_OAUTH_CLIENT_ID = envVariables?.GOOGLE_OAUTH_CLIENT_ID || "";
export const GOOGLE_OAUTH_SECRET = envVariables?.GOOGLE_OAUTH_SECRET || "";
export const FACEBOOK_OAUTH_CLIENT_ID = envVariables?.FACEBOOK_OAUTH_CLIENT_ID || "";
export const FACEBOOK_OAUTH_SECRET = envVariables?.FACEBOOK_OAUTH_SECRET || "";
export const SCHOLARBOX_EXTENSION_ID = envVariables?.SCHOLARBOX_EXTENSION_ID || ""