import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notificationCountAPI } from "../../services/api/notification";
import { logout } from '../auth/LoginSlice';
import { showToast } from '../../Components/Dashboard/common/Toast/index';
const initialState = {
    notificationCountFetched: false,
    notificationCountFetching: false,
    notificationCount: {},
    errorMessage: '',
}

export const notificationCountList = createAsyncThunk(
    'notificationCount',
    async (token, { dispatch, rejectWithValue }) => {
        try {
            const result = await notificationCountAPI(token)
            return result
        } catch (err) {
            dispatch(logout());
            return rejectWithValue(err.response.data)
        }
    }
)

const notificationCountSlice = createSlice({
    name: "notificationCount",
    initialState,
    reducers: {
    },
    extraReducers: (sub) => {
        sub.addCase(notificationCountList.pending, (state) => {
            state.notificationCountFetching = true;
        });

        sub.addCase(notificationCountList.fulfilled, (state, action) => {
            state.notificationCountFetched = true;
            state.notificationCountFetching = false;
            state.notificationCount = action.payload;
        });

        sub.addCase(notificationCountList.rejected, (state, action) => {
            state.notificationCountFetching = false;
            state.errorMessage = action.payload;
        });
    },

})

export default notificationCountSlice.reducer