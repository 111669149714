import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notificationListAPI } from "../../services/api/notification";

const initialState = {
    notificationListFetched: false,
    notificationListFetching: false,
    notificationList: {},
    errorMessage: '',
}

export const notificationListList = createAsyncThunk(
    'notificationList',
    async ({token}, { rejectWithValue }) =>
    {
        try
        {
            const result = await notificationListAPI(token)
            return result
        } catch (err)
        {
            return rejectWithValue(err)
        }
    }
)

const notificationListSlice = createSlice({
    name: "notificationList",
    initialState,
    reducers: {
    },
    extraReducers: (sub) =>
    {
        sub.addCase(notificationListList.pending, (state) =>
        {
            state.notificationListFetching = true;
        });

        sub.addCase(notificationListList.fulfilled, (state, action) =>
        {
            state.notificationListFetched = true;
            state.notificationListFetching = false;
            state.notificationList = action.payload;
        });

        sub.addCase(notificationListList.rejected, (state, action) =>
        {
            state.notificationListFetching = false;
            state.errorMessage = action.payload;
        });
    },

})

export default notificationListSlice.reducer