import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    suggestedChecksFetching: false,
    suggestedChecksFetched: false,
    selectedScholarshipStatus: 0,
    suggestedCollegesChecked: false,
    errorMessage: '',
};

export const suggestedScholarshipChanged = createAsyncThunk(
    'suggestedScholarshipChanged',
    async (body, { rejectWithValue }) => {
        try {
            return body;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const suggestedCollegesChanged = createAsyncThunk(
    'suggestedCollegesChanged',
    async (body, { rejectWithValue }) => {
        try {
            return body;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const resetSuggestedChecks = createAsyncThunk('resetSuggestedChecks', async () => {
    return false;
});

const suggestedStateSlice = createSlice({
    name: 'suggestedStateSlice',
    initialState,
    reducers: {},
    extraReducers: (sub) => {
        sub.addCase(suggestedScholarshipChanged.pending, (state) => {
            state.suggestedChecksFetching = true;
            state.suggestedChecksFetched = false;
        });

        sub.addCase(suggestedScholarshipChanged.fulfilled, (state, action) => {
            state.suggestedChecksFetching = false;
            state.suggestedChecksFetched = true;
            state.selectedScholarshipStatus = action.payload;
        });

        sub.addCase(suggestedScholarshipChanged.rejected, (state, action) => {
            state.suggestedChecksFetching = false;
            state.suggestedChecksFetched = true;
        });

        sub.addCase(suggestedCollegesChanged.pending, (state) => {
            state.suggestedChecksFetching = true;
            state.suggestedChecksFetched = false;
        });

        sub.addCase(suggestedCollegesChanged.fulfilled, (state, action) => {
            state.suggestedChecksFetching = false;
            state.suggestedChecksFetched = true;
            state.suggestedCollegesChecked = action.payload;
        });

        sub.addCase(suggestedCollegesChanged.rejected, (state, action) => {
            state.suggestedChecksFetching = false;
            state.suggestedChecksFetched = true;
        });

        sub.addCase(resetSuggestedChecks.pending, (state) => {
            state.suggestedChecksFetching = true;
            state.suggestedChecksFetched = false;
        });

        sub.addCase(resetSuggestedChecks.fulfilled, (state, action) => {
            state.suggestedChecksFetching = false;
            state.suggestedChecksFetched = true;
            state.selectedScholarshipStatus = action.payload;
            state.suggestedCollegesChecked = action.payload;
        });
    },
});

export default suggestedStateSlice.reducer;
