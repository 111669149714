import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { scholarshipCountAmountAPI } from "../../services/api/scholarships";

const initialState = {
    scholarshipCountAmountFetched: false,
    scholarshipCountAmountFetching: false,
    scholarshipCountAmount: {},
    errorMessage: '',
}

export const scholarshipCountAmountList = createAsyncThunk(
    'scholarshipCountAmount',
    async (token, { rejectWithValue }) =>
    {
        try
        {
            const result = await scholarshipCountAmountAPI(token);
            return result
        } catch (err)
        {
            return rejectWithValue(err)
        }
    }
)

const scholarshipCountAmountSlice = createSlice({
    name: "scholarshipCountAmount",
    initialState,
    reducers: {
    },
    extraReducers: (sub) =>
    {
        sub.addCase(scholarshipCountAmountList.pending, (state) =>
        {
            state.scholarshipCountAmountFetching = true;
        });

        sub.addCase(scholarshipCountAmountList.fulfilled, (state, action) =>
        {
            state.scholarshipCountAmountFetched = true;
            state.scholarshipCountAmountFetching = false;
            state.scholarshipCountAmount = action.payload;
        });

        sub.addCase(scholarshipCountAmountList.rejected, (state, action) =>
        {
            state.scholarshipCountAmountFetching = false;
            state.errorMessage = action.payload;
        });
    },

})

export default scholarshipCountAmountSlice.reducer