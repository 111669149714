import axios from 'axios';
import {
    getChangePasswordTokenURL,
    getChangePasswordURL,
    getForgetPasswordCodeURL,
    getLoginURL,
    getResendForgetPasswordCodeURL,
    getSignUpURL,
    getVerifyForgetPasswordCodeURL,
    getGoogleSSOAuthenticationURL,
    getFacebookSSOAuthenticationURL,
    getAppleSSOAuthenticationURL,
    postUserActivateURL,
} from './endpoints';

export interface AuthData {
    username: string;
    password: string;
}
export interface VerifyForgetPasswordCodeData {
    email: string;
    code: string;
}

export const userAuthenticationAPI = async (body: AuthData) => {
    const AUTHENTICATION_URL = getLoginURL();
    let response = await axios({
        method: 'POST',
        url: `${AUTHENTICATION_URL}`,
        data: body,
    });
    return response.data;
};

export const googleAuthenticaitonAPI = async (body) => {
    const GOOGLE_AUTHENTICATION_URL = getGoogleSSOAuthenticationURL();
    let response = await axios({
        method: 'POST',
        url: `${GOOGLE_AUTHENTICATION_URL}`,
        params: body,
    });
    return response.data;
};
export const appleAuthenticaitonAPI = async (body) => {
    const APPLE_AUTHENTICATION_URL = getAppleSSOAuthenticationURL();
    let response = await axios({
        method: 'POST',
        url: `${APPLE_AUTHENTICATION_URL}`,
        params: body,
    });
    return response.data;
};

export const getFacebookSSOAuthenticationAPI = async (token: string) => {
    const FACEBOOK_AUTHENTICATION_URL = getFacebookSSOAuthenticationURL(token);
    let response = await axios({
        method: 'POST',
        url: `${FACEBOOK_AUTHENTICATION_URL}`,
    });
    return response.data;
};

export const userSignUpAPI = async (body) => {
    const SIGNUP_URL = getSignUpURL();
    let response = await axios({
        method: 'POST',
        url: `${SIGNUP_URL}`,
        data: body,
    });
    return response.data;
};

export const changePasswordAPI = async (params, token) => {
    const CHANGE_PASSWORD_URL = getChangePasswordURL();
    let response = await axios({
        method: 'POST',
        url: `${CHANGE_PASSWORD_URL}`,
        headers: { Authorization: `Bearer ${token}` },
        data: params,
    });
    return response.data;
};

export const forgetPasswordCodeAPI = async (email: string) => {
    const FORGET_PASSWORD_URL = getForgetPasswordCodeURL();
    let response = await axios({
        method: 'POST',
        url: `${FORGET_PASSWORD_URL}`,
        params: {
            email: email,
        },
    });
    return response.data;
};

export const verifyForgetPasswordCodeAPI = async (params: VerifyForgetPasswordCodeData) => {
    const VERIFY_FORGET_PASSWORD_URL = getVerifyForgetPasswordCodeURL();
    let response = await axios({
        method: 'POST',
        url: `${VERIFY_FORGET_PASSWORD_URL}`,
        params: params,
    });
    return response.data;
};

export const resendForgetPasswordCodeAPI = async (email: string) => {
    const RESEND_FORGET_PASSWORD_URL = getResendForgetPasswordCodeURL();
    let response = await axios({
        method: 'POST',
        url: `${RESEND_FORGET_PASSWORD_URL}`,
        params: {
            email: email,
        },
    });
    return response.data;
};

export const changePasswordTokenAPI = async (params) => {
    const CHANGE_PASSWORD_URL = getChangePasswordTokenURL();
    let response = await axios({
        method: 'POST',
        url: `${CHANGE_PASSWORD_URL}`,
        data: params,
    });
    return response.data;
};

export const userActivateAPI = async (userId: number, token: string) => {
    try {
        const USER_ACTIVATE_URL = postUserActivateURL();
        let response = await axios({
            method: 'POST',
            url: `${USER_ACTIVATE_URL}`,
            headers: { Authorization: `Bearer ${token}` },
            params: {
                userId: userId,
            },
        });
        return response.data;
    } catch (error: any) {
        console.log('Error in userActivateAPI', error);
    }
};
