import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import UnAuthorizedPage from '../pages/UnAuthorizedPage';
import { useSelector } from 'react-redux';
import { lazyWithPreload } from '../services/prefetch/prefetch.ts';
const NoPageFound = lazyWithPreload(() => import('../pages/NotFoundPage/NotFoundPage.tsx'));
const AdminDash = lazyWithPreload(() => import('../pages/AdminDashboard/index.tsx'));
const AdminScholarship = lazyWithPreload(() => import('../pages/AdminDashboard/AdminScholarships/index.tsx'));
const AdminUsers = lazyWithPreload(() => import('../pages/AdminDashboard/Users/index.tsx'));
const AdminOrganization = lazyWithPreload(() => import('../pages/AdminDashboard/Organizations/index.tsx'));
const OrgTab = lazyWithPreload(() => import('../pages/AdminDashboard/Organizations/Tab.tsx'));
const ProvideTable = lazyWithPreload(() => import('../pages/AdminDashboard/Organizations/Providers/index.tsx'));
const NonprofitTable = lazyWithPreload(() => import('../pages/AdminDashboard/Organizations/NonProfits/index.tsx'));
const Students = lazyWithPreload(() => import('../pages/AdminDashboard/Students/index.tsx'));
const AdminSponsorPage = lazyWithPreload(() => import('../pages/AdminDashboard/Sponsors/index.tsx'));
const ActiveSponsorsPage = lazyWithPreload(() => import('../Components/Admin/ActiveSponsors/index.tsx'));
const EditSponsorForm = lazyWithPreload(() => import('../Components/Admin/EditSponsor/index.tsx'));
const EditExternalScholarshipPage = lazyWithPreload(() => import('../Components/Admin/External/EditExternalScholarships/index.tsx'));
const AdminEditStudentInfoPage = lazyWithPreload(() => import('../pages/AdminDashboard/Students/EditStudentInfo/index.tsx'));
const AdminSettingsPage = lazyWithPreload(() => import('../pages/AdminDashboard/Settings/index.tsx'));
const AdminAccount = lazyWithPreload(() => import('../pages/AdminDashboard/AdminAccount/Admin.tsx'));
const EditExternalScholarshipDetailsPage = lazyWithPreload(() => import('../Components/Admin/External/EditExternalScholarshipComponent/EditScholarshipDetailsComponent/index.tsx'));
const ChangePasswordComponent = lazyWithPreload(() => import('../pages/Dashboard/MyAccount/ChangePassword/index.tsx'));
import { LoadingFallback } from '../Components/LoadingFallback/LoadingFallback.tsx';


const AdminRoutes = () => {
    const loginDataFromRedux = useSelector((state) => state?.login);
    const { loginDetails } = loginDataFromRedux;
    const userRole = loginDetails?.data?.user?.usersRoles?.[0]?.role?.normalizedName;
    return (
        <Routes>
            <Route path="admin/scholarships" element={<React.Suspense fallback={<LoadingFallback/>}>{['ADMIN'].includes(userRole) ? <AdminScholarship /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="admin/users" element={<React.Suspense fallback={<LoadingFallback/>}>{['ADMIN'].includes(userRole) ? <AdminUsers /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="admin" element={<React.Suspense fallback={<LoadingFallback/>}>{['ADMIN'].includes(userRole) ? <AdminDash /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="admin/organizations" element={<React.Suspense fallback={<LoadingFallback/>}>{['ADMIN'].includes(userRole) ? <AdminOrganization /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="admin/org" element={<React.Suspense fallback={<LoadingFallback/>}>{['ADMIN'].includes(userRole) ? <OrgTab /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="admin/providers" element={<React.Suspense fallback={<LoadingFallback/>}>{['ADMIN'].includes(userRole) ? <ProvideTable /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="admin/non_profits" element={<React.Suspense fallback={<LoadingFallback/>}>{['ADMIN'].includes(userRole) ? <NonprofitTable /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="admin/students" element={<React.Suspense fallback={<LoadingFallback/>}>{['ADMIN'].includes(userRole) ? <Students /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="admin/sponsors" element={<React.Suspense fallback={<LoadingFallback/>}>{['ADMIN'].includes(userRole) ? <ActiveSponsorsPage /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="admin/sponsors/new" element={<React.Suspense fallback={<LoadingFallback/>}>{['ADMIN'].includes(userRole) ? <AdminSponsorPage /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="admin/sponsors/:sponsorId/edit" element={<React.Suspense fallback={<LoadingFallback/>}>{['ADMIN'].includes(userRole) ? <EditSponsorForm /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="admin/external_scholarships/:scholarshipId/edit" element={<React.Suspense fallback={<LoadingFallback/>}>{['ADMIN'].includes(userRole) ? <EditExternalScholarshipPage /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="admin/external_scholarships/new" element={<React.Suspense fallback={<LoadingFallback/>}>{['ADMIN'].includes(userRole) ? <EditExternalScholarshipDetailsPage /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="admin/students/:studentId/edit" element={<React.Suspense fallback={<LoadingFallback/>}>{['ADMIN'].includes(userRole) ? <AdminEditStudentInfoPage /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="admin/my-account" element={<React.Suspense fallback={<LoadingFallback/>}>{['ADMIN'].includes(userRole) ? <AdminAccount /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="admin/settings" element={<React.Suspense fallback={<LoadingFallback/>}>{['ADMIN'].includes(userRole) ? <AdminSettingsPage /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="changePassword" element={<React.Suspense fallback={<LoadingFallback/>}>{['STUDENT', 'COUNSELOR', 'ADMIN'].includes(userRole) ? <ChangePasswordComponent /> : <UnAuthorizedPage />} </React.Suspense>} />
            <Route path="*" element={<NoPageFound />} />
        </Routes>
    )
};

export default AdminRoutes;
