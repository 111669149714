import { createSlice } from '@reduxjs/toolkit';

export const sidebarStatusSlice = createSlice({
    name: 'sidebar',
    initialState: {
        openStatus: true,
    },
    reducers: {
        setSideBarOpenStatus: (state, action) => {
            state.openStatus = action.payload;
        },
    },
});

export const { setSideBarOpenStatus } = sidebarStatusSlice.actions;

export default sidebarStatusSlice.reducer;
