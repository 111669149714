import { getMyAccountURL,getMyAccountCounselorURL,getMyAccountAdminURL } from './endpoints';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

export const fetchStudentDetailsAPI = async (userId, studentAuthTokenNew) => {
    const STUDENT_DETAILS_URL = `${getMyAccountURL}`;
    let res = await axios({
        method: 'GET',
        url: `${STUDENT_DETAILS_URL}?studentId=${userId}`,
        headers: {
            'Authorization': `Bearer ${studentAuthTokenNew}`
        }
    });
    return res.data;
};
export const fetchCounselorDetailsGETAPI = async (authTokenNew) => {
    const COUNSELOR_DETAILS_URL = `${getMyAccountCounselorURL}`;
    let res = await axios({
        method: 'GET',
        url: `${COUNSELOR_DETAILS_URL}`,
        headers: {
            'Authorization': `Bearer ${authTokenNew}`
        }
    });
    return res.data;
};
export const fetchAdminDetailsGETAPI = async (authTokenNew) => {
    const ADMIN_DETAILS_URL = `${getMyAccountAdminURL}`;
    let res = await axios({
        method: 'GET',
        url: `${ADMIN_DETAILS_URL}`,
        headers: {
            'Authorization': `Bearer ${authTokenNew}`
        }
    });
    return res.data;
};