import axios from 'axios';
import { getScholarshipAppliedURL, getScholarshipCountAmountURL, getScholarshipFeaturedURL, getScholarshipStatsURL, getUserActivityURL } from './endpoints';

export const scholarshipStatsAPI = async (token) => {
    const SCHOLARSHIP_STATS_URL = getScholarshipStatsURL();
    let response = await axios({
        method: 'GET',
        url: `${SCHOLARSHIP_STATS_URL}`,
        headers:{Authorization:`Bearer ${token}`}
    }); 
    return response.data;
};

export const scholarshipFeaturedAPI = async (body, token) => {
    const SCHOLARSHIP_FEATURED_URL = getScholarshipFeaturedURL();
    let response = await axios({
      method: 'GET',
      url: `${SCHOLARSHIP_FEATURED_URL}`,
      headers: { Authorization: `Bearer ${token}` },
      params: body
    });
  
    return response.data;
};

export const scholarshipAppliedAPI = async (body, token) => {
    const SCHOLARSHIP_APPLIED_URL = getScholarshipAppliedURL();
    let response = await axios({
      method: 'GET',
      url: `${SCHOLARSHIP_APPLIED_URL}`,
      headers: { Authorization: `Bearer ${token}` },
      params: body
    });
  
    return response.data;
};

export const scholarshipCountAmountAPI = async (token) => {
    const SCHOLARSHIP_COUNT_AMOUNT_URL = getScholarshipCountAmountURL();
    let response = await axios({
      method: 'GET',
      url: `${SCHOLARSHIP_COUNT_AMOUNT_URL}`,
      headers: { Authorization: `Bearer ${token}` },
    });
  
    return response.data;
};

export const userActivityAPI = async (body, token) => {
  const USER_ACTIVITY_URL = getUserActivityURL();
  let response = await axios({
    method: 'GET',
    url: `${USER_ACTIVITY_URL}`,
    headers: { Authorization: `Bearer ${token}` },
    params: body
  });

  return response.data;
};

