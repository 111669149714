import React, { useState } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/styles';
import { Box, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { TextField } from '@material-ui/core';
import { Button, Grid, Typography, Container } from '@mui/material';
import Otpimg from '../../assets/images/landing-login.svg';
import '../../App.css';
import { changePasswordTokenAPI } from '../../services/api/auth';
import { showToast } from '../../Components/Dashboard/common/Toast';
import { signinUser } from '../../store/auth/LoginSlice';
import ComponentContainer from '../../Layout/Wrapper/componentContainer';
import { HeaderText } from '../../Components/Account/CommonStyledComponents/componentHeaderText';

const grid = {
    position: 'relative',
    right: '100px',
    top: '40px',
    border: 'solid red',
};

const divStyle = {
    fontFamily: 'Poppins',
    marginTop: '10px',
    color: 'gray',
};

interface FormData {
    password: string;
    confirmPassword: string;
    passwordError: string; // Custom error message for password mismatch
}

const NewPasswordForm: React.FC<{ onSubmit: (data: FormData) => void }> = ({ onSubmit }) => {
    const methods = useForm<FormData>();

    const { control, handleSubmit, formState } = methods;
    const { errors } = formState;

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordAgain, setShowPasswordAgain] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const togglePasswordAgainVisibility = () => {
        setShowPasswordAgain(!showPasswordAgain);
    };

    return (
        <Box
            sx={{
                margin: { xs: 'auto', md: '0 auto 0 0' },
                height: { xs: 'fit-content', md: '450px' },
                pt: { xs: '275px', sm: '400px', md: '0' },
                width: { xs: '100%', sm: '80%', md: '40%' },
            }}>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <HeaderText sx={{ textAlign: 'left', mt: '32px', mb: '0' }}> Enter new password</HeaderText>
                    <div style={divStyle}>
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <Box position="relative">
                                    <label className="new-passord-field-label" htmlFor="newpassword">
                                        New Password
                                    </label>
                                    <TextField
                                        {...field}
                                        id="newpassword"
                                        placeholder="Enter New Password"
                                        variant="standard"
                                        type={!showPassword ? 'password' : 'text'}
                                        fullWidth
                                        margin="none"
                                        InputLabelProps={{
                                            style: {
                                                fontFamily: 'Poppins',
                                                fontSize: '14px',
                                                color: 'gray',
                                            },
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                            style: {
                                                fontFamily: 'Poppins',
                                                borderRadius: 7,
                                                height: '50px',
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={togglePasswordVisibility} edge="end">
                                                        {showPassword ? (
                                                            <Visibility sx={{ color: 'blue' }} />
                                                        ) : (
                                                            <VisibilityOff sx={{ color: 'blue' }} />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={!!errors.password}
                                        // helperText={errors.password && errors.password.message}

                                        style={{
                                            borderRadius: '7px',
                                            border: errors.password ? '1px solid red' : '1px solid black',
                                            padding: '0 12px',
                                            marginTop: '10px',
                                            marginBottom: '16px',
                                        }}
                                    />
                                    {errors.password && (
                                        <Typography
                                            position="absolute"
                                            left="0px"
                                            bottom="-2px"
                                            fontSize="12px"
                                            color="#f44336">
                                            {errors.password.message}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                            rules={{
                                required: 'Password is required',
                                minLength: {
                                    value: 6,
                                    message: 'Password must be at least 6 characters long',
                                },
                            }}
                        />
                        <Controller
                            name="confirmPassword"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <Box position="relative">
                                    <label className="new-passord-field-label" htmlFor="newpassword">
                                        Re-enter New Password
                                    </label>
                                    <TextField
                                        {...field}
                                        // label="Re-Enter New Password"
                                        placeholder="Re-enter New Password"
                                        variant="standard"
                                        type={!showPasswordAgain ? 'password' : 'text'}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            style: {
                                                fontFamily: 'Poppins',
                                                fontSize: '14px',
                                                color: 'gray',
                                            },
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                            style: {
                                                fontFamily: 'Poppins',
                                                borderRadius: 7,
                                                height: '50px',
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={togglePasswordAgainVisibility} edge="end">
                                                        {showPasswordAgain ? (
                                                            <Visibility sx={{ color: 'blue' }} />
                                                        ) : (
                                                            <VisibilityOff sx={{ color: 'blue' }} />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={!!errors.confirmPassword}
                                        // helperText={errors.confirmPassword && errors.confirmPassword.message}
                                        style={{
                                            borderRadius: '7px',
                                            border: errors.confirmPassword ? '1px solid red' : '1px solid black',
                                            padding: '0 12px',
                                            marginTop: '10px',
                                        }}
                                    />
                                    {errors.confirmPassword && (
                                        <Typography
                                            position="absolute"
                                            left="0px"
                                            bottom="-10px"
                                            fontSize="12px"
                                            color="#f44336">
                                            {errors.confirmPassword.message}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                            rules={{
                                required: 'Confirm password is required',
                                validate: (value) => {
                                    const passwordValue = methods.getValues().password;
                                    return value === passwordValue || 'Passwords do not match';
                                },
                            }}
                        />
                    </div>

                    <Button
                        type="submit"
                        variant="contained"
                        className="button-style"
                        style={{
                            backgroundColor: '#F7941D',
                            color: '#FFF',
                            fontWeight: '600',
                            width: '100%',
                            marginTop: '48px',
                            fontFamily: 'Poppins',
                            borderRadius: '20px',
                            height: '36px',
                            textTransform: 'capitalize',
                        }}>
                        Submit <ArrowForwardIosIcon style={{ paddingLeft: '15px', fontSize: '30px' }} />
                    </Button>
                </form>
            </FormProvider>
        </Box>
        // </Grid>
    );
};

const NewPasswordPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { email, code } = location.state || {};
    const [loading, setLoading] = React.useState(false);

    let isApplying = false;

    const onSubmit = async (data) => {
        if (isApplying) return; // If already applying, return early
        isApplying = true;
        setLoading(true);
        const params = {
            email: email,
            token: code,
            password: data.password,
            confirmPassword: data.confirmPassword,
        };

        try {
            // Call changePasswordTokenAPI with await
            const response = await changePasswordTokenAPI(params);

            if (response.code === 200) {
                // debugger
                // Handle success
                // const payload = {
                //   email: email,
                //   password: data.password,
                //   rememberMe: true,
                // };
                navigate('/users/login');
                showToast(`Password changed successfully!`, {
                    type: 'success',
                });

                // const res = await dispatch(signinUser(payload));
                // // //('res', res);

                // if (res.payload.code === 200) {
                //   showToast(`Password changed successfully!`, {
                //     type: 'success'
                //   });


                //   if (res.payload.data.user.usersRoles[0].role.title.toLowerCase() === 'student') {
                //     navigate('/dashboard/student');
                //   }
                //  else if (res.payload.data.user.usersRoles[0].role.title.toLowerCase() === 'counselor') {
                //     navigate('/dashboard/counselor');
                //   }
                //   else if(res.payload.data.user.usersRoles[0].role.title.toLowerCase() === 'admin'){
                //     navigate('/dashboard/admin');
                //   }
                //   else{
                //     navigate('users/login');
                //   }
                // }
            } else {
                // Handle failure
                showToast(response.message[0], {
                    type: 'error',
                });
                navigate('/users/login');
            }
        } catch (error) {
            showToast(`Password not changed!`, {
                type: 'error',
            });
        } finally {
            setLoading(false);
            isApplying = false; // Reset applying flag
        }
    };

    return (
        <ComponentContainer
            styleProps={{
                backgroundImage: `url(${Otpimg})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: { xs: '280px', sm: '430px', md: '480px' },
                backgroundPosition: { xs: 'center top 16px', md: 'right 20px bottom -7px' },
                display: 'flex',
                justifyContent: 'flex-start',
            }}>
            <NewPasswordForm onSubmit={onSubmit} />
        </ComponentContainer>
    );
};

export default NewPasswordPage;
