import axios from 'axios';
import { getNotificationCountURL, getNotificationListURL } from './endpoints';

export const notificationListAPI = async (token) => {
  const NOTIFICATION_LIST_URL = getNotificationListURL();
  let response = await axios({
    method: 'GET',
    url: `${NOTIFICATION_LIST_URL}`,
    headers:{Authorization:`Bearer ${token}`},
  }); 
  return response.data;
};

export const notificationCountAPI = async (token) => {
  const NOTIFICATION_COUNT_URL = getNotificationCountURL();
  let response = await axios({
    method: 'GET',
    url: `${NOTIFICATION_COUNT_URL}`,
    headers:{Authorization:`Bearer ${token}`},
  }); 
  return response.data;
};
  