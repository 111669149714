import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import UnAuthorizedPage from '../pages/UnAuthorizedPage';
import { lazyWithPreload } from '../services/prefetch/prefetch.ts';
import { useSelector } from 'react-redux';
const CounselorStudentPage = lazyWithPreload(() => import('../pages/CounselorDashboard/Students/index.tsx'));
const CounselorApplicationManagerPage = lazyWithPreload(() => import('../pages/CounselorDashboard/ApplicationManager/index.tsx'));
const ListManagerPage = lazyWithPreload(() => import('../pages/CounselorDashboard/ListManger/index.tsx'));
const AddScholarship = lazyWithPreload(() => import('../Components/AddScholarship/index.tsx'));
const CounselorViewScholarship = lazyWithPreload(() => import('../pages/CounselorDashboard/ApplicationManager/ViewScholarship/index.tsx'));
const CounselorApplyScholarship = lazyWithPreload(() => import('../pages/CounselorDashboard/ApplicationManager/ApplyScholarship/index.tsx'));
const NewListManager = lazyWithPreload(() => import('../pages/CounselorDashboard/ListManger/New/index.tsx'));
const CounselorCollegesPage = lazyWithPreload(() => import('../pages/CounselorDashboard/Colleges/index.tsx'));
const NewListManagerAdd = lazyWithPreload(() => import('../pages/CounselorDashboard/ListManger/AddScholarship/index.tsx'));
const Table = lazyWithPreload(() => import('../pages/CounselorDashboard/ListManger/Multiple/index.tsx'));
const PaperScholarship = lazyWithPreload(() => import('../pages/CounselorDashboard/ListManger/PaperScholarship/index.tsx'));
const ListOfUrl = lazyWithPreload(() => import('../pages/CounselorDashboard/ListManger/List_Of_Url/index.tsx'));
const CounselorAccountDetailsPage = lazyWithPreload(() => import('../pages/CounselorDashboard/MyAccount/index.tsx'));
const NumberOfApplicants = lazyWithPreload(() => import('../pages/CounselorDashboard/ApplicationManager/NoOfApplicantsPage/index.tsx'));
const OrganizationDetailTabNavigation = lazyWithPreload(() => import('../pages/CounselorDashboard/OrganizationDetail/index.tsx'));
const NoPageFound = lazyWithPreload(() => import('../pages/NotFoundPage/NotFoundPage.tsx'));
const ChangePasswordComponent = lazyWithPreload(() => import('../pages/Dashboard/MyAccount/ChangePassword/index.tsx'));
import { LoadingFallback } from '../Components/LoadingFallback/LoadingFallback.tsx';

const CounselorRoutes = () => {
    const loginDataFromRedux = useSelector((state) => state?.login);
    const { loginDetails } = loginDataFromRedux;
    const userRole = loginDetails?.data?.user?.usersRoles?.[0]?.role?.normalizedName;
    return (
    <Routes>
         <Route path="counselor" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <CounselorStudentPage /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/application-manager" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <CounselorApplicationManagerPage /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/organization/profile" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <OrganizationDetailTabNavigation /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/organization/branding" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <OrganizationDetailTabNavigation /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/organization/colleges" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <CounselorCollegesPage /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/schools/:organizationId/scholarships/list" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <ListManagerPage /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/schools/scholarships/list" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <ListManagerPage /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/schools/:organizationId/scholarships/:scholarshipId/applications" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <NumberOfApplicants /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/schools/:organizationId/scholarships/:scholarshipId/applications/:studentId" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <NumberOfApplicants /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/schools/:organizationId/scholarships/list/list_booster" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <ListManagerPage /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/schools/:organizationId/scholarships/list/search_result" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <ListManagerPage /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="/counselor/default_organizations" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <ListManagerPage /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/list-manager/new" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <NewListManager /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/list-manager/new/application" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <NewListManagerAdd /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/list-manager/new/table" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <Table /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/list-manager/paper-scholarship" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <PaperScholarship /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/list-manager/new/list-table" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <ListOfUrl /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/add-scholarship" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <AddScholarship /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/add-scholarship/general-info" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <AddScholarship /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/edit-scholarship/:scholarshipId/general-info" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <AddScholarship /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/add-scholarship/requirements-info" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <AddScholarship /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/edit-scholarship/:scholarshipId/requirements-info" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <AddScholarship /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/add-scholarship/review" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <AddScholarship /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/edit-scholarship/:scholarshipId/review" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <AddScholarship /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/view-scholarship" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <CounselorViewScholarship /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/apply-scholarship" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <CounselorApplyScholarship /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="counselor/my-account" element={<React.Suspense fallback={<LoadingFallback/>}>{['COUNSELOR'].includes(userRole) ? <CounselorAccountDetailsPage /> : <UnAuthorizedPage />}</React.Suspense>} />
            <Route path="*" element={<NoPageFound />} />
            <Route path="changePassword" element={<React.Suspense fallback={<LoadingFallback/>}>{['STUDENT', 'COUNSELOR', 'ADMIN'].includes(userRole) ? <ChangePasswordComponent /> : <UnAuthorizedPage />} </React.Suspense>} />
    </Routes>
)
};

export default CounselorRoutes;
