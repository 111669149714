import { Box, Container } from '@mui/material';
import ComponentContainer from '../../Layout/Wrapper/componentContainer';
import TypographyBody5 from '../../Components/ui/Body5';
const scrollToTop = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
};
const CopyRightFooter = () => {
    const date = new Date();
    let year = date.getFullYear();
    return (
        // <ComponentContainer styleProps={{backgroundColor:'#FFF'}}>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            // mt="50px"
            // pt="24px"
            pt="12px"
            pb="12px"
            width="100%"
            borderTop="0.98px solid var(--Dark, rgba(0, 0, 0, 0.12))">
            <TypographyBody5
                sx={{ fontSize: '12px', fontWeight: 500, color: '##1c2f50' }}
                text={`© Copyright - ${year} Scholar Box`}
            />
        </Box>
        // </ComponentContainer>
    );
};

export default CopyRightFooter;
