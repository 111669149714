import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import UnAuthorizedPage from '../pages/UnAuthorizedPage';
import { lazyWithPreload } from '../services/prefetch/prefetch.ts';
import { useSelector } from 'react-redux';
const DashboardHomePage = lazyWithPreload(() => import('../pages/Dashboard/Home/index.tsx'));
const Scholarships = lazyWithPreload(() => import('../pages/Dashboard/Scholarships/index.tsx'));
const LearningCenter = lazyWithPreload(() => import('../pages/Dashboard/LearningCenter/index.tsx'));
const LearningCenterMoreInfo = lazyWithPreload(() => import('../pages/Dashboard/LearningCenter/ArticleDetail.tsx'));
const CollegesPage = lazyWithPreload(() => import('../pages/Colleges/index.tsx'));
const Faq = lazyWithPreload(() => import('../pages/Dashboard/Faq/index.tsx'));
const Settings = lazyWithPreload(() => import('../pages/Dashboard/Settings/index.tsx'));
const StudentMyAccount = lazyWithPreload(() => import('../pages/Dashboard/MyAccountPage/index.tsx'));
const ChangePasswordComponent = lazyWithPreload(() => import('../pages/Dashboard/MyAccount/ChangePassword/index.tsx'));
const ActivityPage = lazyWithPreload(() => import('../pages/Dashboard/Activity/index.tsx'));
const ApplyScholarshipPage = lazyWithPreload(() => import('../Components/ApplyProcess/index.tsx'));
const NoPageFound = lazyWithPreload(() => import('../pages/NotFoundPage/NotFoundPage.tsx'));
import { LoadingFallback } from '../Components/LoadingFallback/LoadingFallback.tsx';

const StudentRoutes = () => {
    const loginDataFromRedux = useSelector((state) => state?.login);
    const { loginDetails } = loginDataFromRedux;
    const userRole = loginDetails?.data?.user?.usersRoles?.[0]?.role?.normalizedName;
    return (
    <Routes>
        <Route path="student" element={<React.Suspense fallback={<LoadingFallback/>}>{['STUDENT'].includes(userRole) ? <DashboardHomePage /> : <UnAuthorizedPage />}</React.Suspense>} />
        <Route path="student/scholarships/:activeTabFromParams" element={<React.Suspense fallback={<LoadingFallback/>}>{['STUDENT'].includes(userRole) ? <Scholarships /> : <UnAuthorizedPage />}</React.Suspense>} />
        <Route path="student/colleges/:statusTitle" element={<React.Suspense fallback={<LoadingFallback/>}>{['STUDENT'].includes(userRole) ? <CollegesPage /> : <UnAuthorizedPage />}</React.Suspense>} />
        <Route path="student/learningCenter" element={<React.Suspense fallback={<LoadingFallback/>}>{['STUDENT'].includes(userRole) ? <LearningCenter /> : <UnAuthorizedPage />}</React.Suspense>} />
        <Route path="student/learningCenter/:moreInfo" element={<React.Suspense fallback={<LoadingFallback/>}>{['STUDENT'].includes(userRole) ? <LearningCenterMoreInfo /> : <UnAuthorizedPage />}</React.Suspense>} />
        <Route path="student/Faq" element={<React.Suspense fallback={<LoadingFallback/>}>{['STUDENT'].includes(userRole) ? <Faq /> : <UnAuthorizedPage />}</React.Suspense>} />
        <Route path="student/Settings" element={<React.Suspense fallback={<LoadingFallback/>}>{['STUDENT'].includes(userRole) ? <Settings /> : <UnAuthorizedPage />}</React.Suspense>} />
        <Route path="my-account" element={<React.Suspense fallback={<LoadingFallback/>}>{['STUDENT', 'ADMIN'].includes(userRole) ? <StudentMyAccount /> : <UnAuthorizedPage />}</React.Suspense>} />
        <Route path="student/application-process/personal_info" element={<React.Suspense fallback={<LoadingFallback/>}>{['STUDENT'].includes(userRole) ? <ApplyScholarshipPage /> : <UnAuthorizedPage />}</React.Suspense>} />
        <Route path="student/application-process/education_info" element={<React.Suspense fallback={<LoadingFallback/>}>{['STUDENT'].includes(userRole) ? <ApplyScholarshipPage /> : <UnAuthorizedPage />}</React.Suspense>} />
        <Route path="student/application-process/attachment" element={<React.Suspense fallback={<LoadingFallback/>}>{['STUDENT'].includes(userRole) ? <ApplyScholarshipPage /> : <UnAuthorizedPage />}</React.Suspense>} />
        <Route path="student/application-process/review" element={<React.Suspense fallback={<LoadingFallback/>}>{['STUDENT'].includes(userRole) ? <ApplyScholarshipPage /> : <UnAuthorizedPage />}</React.Suspense>} />
        <Route path="student/application-process/complete" element={<React.Suspense fallback={<LoadingFallback/>}>{['STUDENT'].includes(userRole) ? <ApplyScholarshipPage /> : <UnAuthorizedPage />}</React.Suspense>} />
        <Route path="changePassword" element={<React.Suspense fallback={<LoadingFallback/>}>{['STUDENT', 'COUNSELOR', 'ADMIN'].includes(userRole) ? <ChangePasswordComponent /> : <UnAuthorizedPage />} </React.Suspense>} />
        <Route path="student/activity" element={<React.Suspense fallback={<LoadingFallback/>}>{['STUDENT'].includes(userRole) ? <ActivityPage /> : <UnAuthorizedPage />}  </React.Suspense>} />
        <Route path="*" element={<NoPageFound />} />
    </Routes>
)
};

export default StudentRoutes;
