import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import CounselorRoutes from "./CounselorRoutes";
import StudentRoutes from "./StudentRoutes";
import AdminRoutes from "./AdminRoutes";
import { lazyWithPreload } from '../services/prefetch/prefetch.ts';

const Dashboard = lazyWithPreload(() => import('../pages/Dashboard/index'));
const NoPageFound = lazyWithPreload(() => import('../pages/NotFoundPage/NotFoundPage.tsx'));

const DashboardRoutes = () => {
    const loginDataFromRedux = useSelector((state) => state?.login);
    const { loginDetails } = loginDataFromRedux;
    const userRole = loginDetails?.data?.user?.usersRoles?.[0]?.role?.normalizedName;
    return(
        <Routes>
            <Route path ="/" element={<Dashboard/>}>
            {userRole === 'ADMIN' && <Route path="/*" element={<AdminRoutes/>} />}
            {userRole === 'COUNSELOR' && <Route path="/*" element={<CounselorRoutes/>} />}
            {userRole === 'STUDENT' && <Route path="/*" element={<StudentRoutes/>} />}
            <Route path="*" element={<NoPageFound />} />
            </Route>
        </Routes>
    )
}

export default DashboardRoutes;