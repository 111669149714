import React from 'react';
import { Typography, styled } from '@mui/material';

interface Body5 {
    text: string;
    sx?: object;
}

const TypographyBody5: React.FC<Body5> = ({ text, sx }) => {
    const HeaderText = styled(Typography)(({ theme }) => ({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontSize: '12px',
        fontWeight: '400',
        letterSpacing: '0.8px',
        lineHeight: '150%',
    }));

    return (
        <HeaderText variant="body2" sx={sx}>
            {text}
        </HeaderText>
    );
};

export default TypographyBody5;
