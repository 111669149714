import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'; // Import Provider from react-redux
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from './Theme';
import App from './App.tsx'
import { createRoot } from 'react-dom/client'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { store, persistor } from './store/store';
import { PersistGate } from "redux-persist/integration/react";
import { HelmetProvider } from 'react-helmet-async';

const helmetContext = {};

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}> {/* Wrap with Provider */}
      <HelmetProvider context={helmetContext}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <PersistGate loading={null} persistor={persistor}>
            <App />
            <ToastContainer
              position="bottom-right"
              autoClose={3000}
              hideProgressBar={false}
              closeOnClick
              pauseOnHover
              draggable
              theme="light"
            />
          </PersistGate>
        </ThemeProvider>
      </HelmetProvider>
    </Provider>
  </React.StrictMode>
);

