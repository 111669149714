import { SCHOLARBOX_EXTENSION_ID } from '../api/config';
export const sendMessageToExtension = (auth_token: string) => {
    const extensionId = SCHOLARBOX_EXTENSION_ID;
    if (typeof chrome !== 'undefined') {
        if (chrome.runtime && typeof chrome.runtime.sendMessage === 'function') {
            chrome.runtime.sendMessage(extensionId, { auth_token: auth_token })
                .then((response) => {
                    // Handle successful response if needed
                })
                .catch((error) => {
                    // Error occurred while sending message
                    console.error("Error sending message to extension:", error);
                });
        } else {
            console.log("chrome.runtime.sendMessage is not available.");
        }
    } else {
        console.log("chrome object is not available.");
    }

}

export const handleLogoutMessageOnExtension = () => {
    // const extensionId = 'efjegjafajkjkboenffhhcamacfjncoj';
    const extensionId = SCHOLARBOX_EXTENSION_ID;
    if (typeof chrome !== 'undefined') {
        if (chrome.runtime && chrome.runtime.sendMessage) {
            chrome.runtime.sendMessage(extensionId, { clearStorage: true, auth_token: null }, function (response) {
            });
        }
        else {
            console.log("Could not log out from Extension.");
        }
    }
}