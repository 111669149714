import React from 'react';
import { Typography, useTheme, createTheme, ThemeProvider, styled } from '@mui/material';

interface Header5TextProps {
    text: string;
    sx?: object;
}

const Header5Text: React.FC<Header5TextProps> = ({ text, sx }) => {
    const Header5TextStyled = styled(Typography)(({ theme }) => ({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontSize: '1rem',
        letterSpacing: '0.05rem',
        fontWeight: 500,
        margin: '8px 0px',
        lineHeight: '125% !important',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
            margin: '0px 0px'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: '1rem',
        },
        textAlign: 'center'
    }));
    return (
        <Header5TextStyled variant="h5" sx={sx}>
            {text}
        </Header5TextStyled>
    );
};

export default Header5Text;
