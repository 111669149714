import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userActivityAPI } from "../../services/api/scholarships";

const initialState = {
    userActivityFetched: false,
    userActivityFetching: false,
    userActivity: {},
    errorMessage: '',
}

export const userActivityList = createAsyncThunk(
    'userActivity',
    async ({body, token}, { rejectWithValue }) =>
    {
        try
        {
            const result = await userActivityAPI(body, token);
            return result
        } catch (err)
        {
            return rejectWithValue(err)
        }
    }
)

const userActivitySlice = createSlice({
    name: "userActivity",
    initialState,
    reducers: {
    },
    extraReducers: (sub) =>
    {
        sub.addCase(userActivityList.pending, (state) =>
        {
            state.userActivityFetching = true;
        });

        sub.addCase(userActivityList.fulfilled, (state, action) =>
        {
            state.userActivityFetched = true;
            state.userActivityFetching = false;
            state.userActivity = action.payload;
        });

        sub.addCase(userActivityList.rejected, (state, action) =>
        {
            state.userActivityFetching = false;
            state.errorMessage = action.payload;
        });
    },

})

export default userActivitySlice.reducer