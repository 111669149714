import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { scholarshipFeaturedAPI } from "../../services/api/scholarships";

const initialState = {
    scholarshipFeaturedFetched: false,
    scholarshipFeaturedFetching: false,
    scholarshipFeatured: {},
    errorMessage: '',
}

export const scholarshipFeaturedList = createAsyncThunk(
    'scholarshipFeatured',
    async ({body, token}, { rejectWithValue }) =>
    {
        try
        {
            const result = await scholarshipFeaturedAPI(body, token);
            return result
        } catch (err)
        {
            return rejectWithValue(err)
        }
    }
)


const scholarshipFeaturedSlice = createSlice({
    name: "scholarshipFeatured",
    initialState,
    reducers: {
    },
    extraReducers: (sub) =>
    {
        sub.addCase(scholarshipFeaturedList.pending, (state) =>
        {
            state.scholarshipFeaturedFetching = true;
        });

        sub.addCase(scholarshipFeaturedList.fulfilled, (state, action) =>
        {
            state.scholarshipFeaturedFetched = true;
            state.scholarshipFeaturedFetching = false;
            state.scholarshipFeatured = action.payload;
        });

        sub.addCase(scholarshipFeaturedList.rejected, (state, action) =>
        {
            state.scholarshipFeaturedFetching = false;
            state.errorMessage = action.payload;
        });
    },

})

export default scholarshipFeaturedSlice.reducer