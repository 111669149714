import {
    AppBar,
    Grid,
    CssBaseline,
    Box,
    Button,
    IconButton,
    Drawer,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { COLOR_LINKTEXT, COLOR_ORANGE_PRIMARY, COLOR_DARK, COLOR_WHITE, COLOR_SECONDARY, COLOR_TEXT } from '../../constants/color';
import MenuIcon from '@mui/icons-material/Menu';
import HeaderPopover from './headerPopover';
import ComponentContainer from '../../Layout/Wrapper/componentContainer';
import LogoImage from '../../assets/images/Scholar-Box-Logo.png';
// import LogoImage from '../../assets/images/scholar-box-logo-png.png';
import { handlePrefetchPage } from '../../utils/prefetchUtils';
import SwipeableTemporaryDrawer from './headerDrawer';


const defaultTheme = createTheme({});
const { breakpoints } = defaultTheme;


const CustomButton = styled(Button)((theme) => ({
    display: 'flex',
    justifyContent: 'center',
    fontStyle: 'normal',
    alignItems: 'center',
    flexShrink: 0,
    borderRadius: '36px',
    color: COLOR_WHITE,
    fontFamily: 'Poppins',
    fontSize: '16px',
    border: `2px solid ${COLOR_ORANGE_PRIMARY} !important`,
    padding: '6px 12px',
    lineHeight: '125%',
    letterSpacing: '0.8px',
    fontWeight: 500,
    '&:hover': {
        backgroundColor: `${COLOR_ORANGE_PRIMARY} !important`,
    },
}));
const CustomButton2 = styled(Button)((theme) => ({
    display: 'flex!important',
    justifyContent: 'center !important',
    fontStyle: 'normal !important',
    alignItems: 'center !important',
    flexShrink: 0,
    borderRadius: '6.24px !important',
    color: COLOR_ORANGE_PRIMARY,
    fontFamily: 'Poppins !important',
    fontSize: '16px !important',
    fontWeight: '600 !important',
    padding: '6px 8px!important',
    lineHeight: '175%',
    backgroundColor: COLOR_WHITE,
    border: `1.5px solid ${COLOR_ORANGE_PRIMARY}`,
    '&:hover': {
        backgroundColor: `${COLOR_WHITE} !important`,
    },
}));

const CustomLink = styled(Link)(() => ({

    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '0.8px',
    lineHeight: '150%',
    fontStyle: 'normal',
    textDecoration: 'none',
    '-webit-transition': 'none',
    fill: '#FFF',
    color: '#000',
    '&:hover':{
        textDecoration: 'underline',
        textUnderlineOffset: '4px', 
    }
}));
const CustomPopoverLink = styled(Link)(() => ({
    fontFamily: 'Poppins !important',
    fontSize: '0.825rem !important',
    fontWeight: 500,
    letterSpacing: '0.05rem !important',
    lineHeight: '125% !important',
    fontStyle: 'normal',
    textDecoration: 'none',
    '-webit-transition': 'none',
    fill: '#FFF',
    color: COLOR_DARK,
    '&:hover': {
        color: COLOR_ORANGE_PRIMARY
    }
}));


const Header = () => {
    const currentPath = window.location.pathname;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [userRoleRedirectPath, setUserRoleRedirectPath] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setAnchorEl(null);
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    // alert(JSON.stringify(windowWidth))
    const currentWindowWidth = windowWidth;
    // alert(JSON.stringify(currentWindowWidth))
    const loginDataFromRedux = useSelector((state) => state.login);
    const { error, isAuth, isLoading, loginDetails, signinResponse, token } = loginDataFromRedux;
    const loggedInUserData = useSelector((state: any) => state.login.loginDetails.data);
    const userRole = loggedInUserData?.user?.usersRoles?.[0]?.role?.title;
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);


    const moreOptionsForNavBar = [
        { id: 'schoolCounselor', name: 'School Counselor', redirectTo: '/aboutCounselor' },
        { id: 'aboutUs', name: 'About Us', redirectTo: '/aboutUs/' },
        { id: 'contact', name: 'Contact Us', redirectTo: '/contact/' },
    ];
    const anchorRef = useRef<HTMLDivElement>(null);
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
    const handlePopoverClick = () => {
        setPopoverOpen((prevState) => !prevState);
    };
    const handleLargeScreenPopoverClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setPopoverOpen(false);
    };
    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        setPopoverOpen(false);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 740) {
                setPopoverOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
        handleResize();
    }, []);




    const handleSignUpClick = () => {
        navigate('/signup/');
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };



    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        if (userRole === 'Student') {
            setUserRoleRedirectPath('dashboard/student/');
        } else if (userRole === 'Admin') {
            setUserRoleRedirectPath('dashboard/admin/');
        } else if (userRole === 'Counselor') {
            setUserRoleRedirectPath('dashboard/counselor/');
        } else {
            setUserRoleRedirectPath('dashboard/student');
        }
    }, [userRole]);



    return (
        <AppBar
            sx={{
                backgroundColor: 'white !important',
                display: 'flex',
                alignItems: 'center',
                boxShadow: 'none',
                borderBottom: `1px solid #d1d1d1`,
            }}
            position="sticky">
            <CssBaseline />
            <ComponentContainer>
                {/* Grid Changed to Box due to responsiveness issue */}
                <Box
                    className="header"
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <Box className="banner-div">
                        <CustomLink to="/">
                            <img
                                style={{
                                    height: window.innerWidth > 768 ? '55px' : '50px',
                                }}
                                src={LogoImage} alt="Logo" />
                        </CustomLink>
                    </Box>

                    {currentWindowWidth > 740 ? (
                        <Box className="link-div" sx={{ width: 'fit-content' }}>
                            {!isAuth && (
                                <Box sx={{ width: 'fit-content' }}>
                                    <CustomLink className="student-counselor-text" to="/aboutCounselor" onMouseEnter={() => handlePrefetchPage('schoolCounselorPage')}>
                                        School Counselor
                                    </CustomLink>
                                </Box>
                            )}

                            <Box>
                                {isAuth ? (
                                    <>
                                        {currentPath !== '/signUp/yourEducationDetails' && currentPath !== '/signUp/moreAboutYou' && (
                                            <CustomLink className="student-counselor-text" to={`${userRoleRedirectPath}`} >
                                                Dashboard
                                            </CustomLink>
                                        )}
                                    </>

                                ) :
                                    (
                                        <CustomLink className="student-counselor-text" to="/users/login" onMouseEnter={() => handlePrefetchPage('loginPage')}>
                                            Log In
                                        </CustomLink>

                                    )}
                            </Box>
                            {!isAuth && (
                                <Box>
                                    <CustomLink to='/signup' style={{ color: COLOR_ORANGE_PRIMARY }} onMouseEnter={() => handlePrefetchPage('signUpPage')}>
                                            Sign Up
                                    </CustomLink>
                                </Box>
                            )}
                            <SwipeableTemporaryDrawer />
                        </Box>
                    ) : (
                        <Box>
                            <IconButton
                                // size="large"
                                // edge="end"
                                onClick={handleClick}
                            >
                                <MenuIcon className="menu-button"
                                />
                            </IconButton>
                            <Drawer className="header-drawer" anchor="right" open={open} onClose={handleClose}>
                                <HeaderPopover onClose={handleClose} />
                            </Drawer>
                        </Box>
                    )}
                </Box>
            </ComponentContainer>
        </AppBar >
    );
};
export default Header;
