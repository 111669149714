import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userAuthenticationAPI, googleAuthenticaitonAPI, getFacebookSSOAuthenticationAPI, appleAuthenticaitonAPI, userActivateAPI } from "../../services/api/auth";
import { logoutSuccess as logoutSuccessUserDetails } from '../userAccountDetails/StudentDetailsSlice';
import { handleLogoutMessageOnExtension } from "../../services/extension/sendMessageToExtension";
import { resetSuggestedChecks } from "../../store/student/SuggestedStateSlice";
const initialState = {
  isLoading: false,
  loginDetails: "",
  isAuth: false,
  token: "",
  error: "",
  signinResponse: null,
  stat: {},
}

interface userObject {
  code: number;
  data: any;
  details: any;
  message: any;
  success: boolean;
}

export const signinUser = createAsyncThunk(
  'signinuser',
  async (body, { rejectWithValue }) => {
    try {
      const response = await userAuthenticationAPI(body);
      return response
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const googleSignInUser = createAsyncThunk(
  'googlesigninuser',
  async (body, { rejectWithValue }) => {
    try {
      const response = await googleAuthenticaitonAPI(body);
      return response;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const appleSignInUser = createAsyncThunk(
  'applesigninuser',
  async (body, { rejectWithValue }) => {
    try {
      const response = await appleAuthenticaitonAPI(body);
      return response;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const facebookSignInUser = createAsyncThunk(
  'facebooksigninuser',
  async (body, { rejectWithValue }) => {
    try {
      const response = await getFacebookSSOAuthenticationAPI(body);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk(
  'logoutuser',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      // Clear the authentication data and set isAuth to false
      dispatch(logoutSuccess());
      // debugger;
      dispatch(logoutSuccessUserDetails());
      dispatch(resetSuggestedChecks());
      handleLogoutMessageOnExtension();

      return 'Logout Success';
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const activateUser = createAsyncThunk(
  'activateUser',
  async (user: userObject, { rejectWithValue }) => {
    try {
      const userId = user.data.user.id;
      const token = user.data.auth_token;
      userActivateAPI(userId, token).then(async (response) => {
        console.log("activate api response ", response)
        if (response.code !== 200) {
          return rejectWithValue(response.data);
        }
      })
      return user;
    }
    catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginPending: (state) => {
      state.isLoading = true;
      state.isAuth = false;
    },
    loginSuccess: (state, action) => {
      state.isLoading = false;
      if (action.payload.success) {
        state.isAuth = true;
        state.loginDetails = JSON.stringify(action.payload);
        state.signinResponse = action.payload;
      }
      else {
        state.isAuth = false;
        state.error = action.payload.message || "Login failed";
      }
    },
    loginFail: (state, action) => {
      state.isLoading = false;
      state.isAuth = false;
      state.error = action.payload;
    },
    // Add a logout reducer to handle the logout process
    logoutSuccess: (state) => {
      state.isAuth = false;
      state.loginDetails = "";
      state.token = "";
      state.stat = {};
    },
  },
  extraReducers: (user) => {
    user.addCase(signinUser.pending, (state) => {
      state.isLoading = true;
      state.isAuth = false;
    });

    user.addCase(signinUser.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.success) {
        state.isAuth = true;
        state.loginDetails = action.payload;
        state.token = JSON.stringify(action);
        state.signinResponse = action.payload;
      }
      else {
        state.isAuth = false;
        state.error = action.payload.message || "Login Failed";
      }
    });
    user.addCase(googleSignInUser.pending, (state) => {
      state.isLoading = true;
      state.isAuth = false;
    })
    user.addCase(googleSignInUser.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.success) {
        state.isAuth = true;
        state.loginDetails = action.payload;
        state.token = JSON.stringify(action);
        state.signinResponse = action.payload;
      }
      else {
        state.isAuth = false;
        state.error = action.payload.message || "Login Failed";
      }
    });
    user.addCase(googleSignInUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isAuth = false;
      state.error = action.payload.message || "Login Failed";
    });
    user.addCase(appleSignInUser.pending, (state) => {
      state.isLoading = true;
      state.isAuth = false;
    })
    user.addCase(appleSignInUser.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.success) {
        state.isAuth = true;
        state.loginDetails = action.payload;
        state.token = JSON.stringify(action);
        state.signinResponse = action.payload;
      }
      else {
        state.isAuth = false;
        state.error = action.payload.message || "Login Failed";
      }
    });
    user.addCase(appleSignInUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isAuth = false;
      state.error = action.payload.message || "Login Failed";
    });
    user.addCase(facebookSignInUser.pending, (state) => {
      state.isLoading = true;
      state.isAuth = false;
    })
    user.addCase(facebookSignInUser.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.success) {
        state.isAuth = true;
        state.loginDetails = action.payload;
        state.token = JSON.stringify(action);
        state.signinResponse = action.payload;
      }
      else {
        state.isAuth = false;
        state.error = action.payload.message || "Login Failed";
      }
    });
    user.addCase(facebookSignInUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isAuth = false;
      state.error = action.payload.mesage || "Login Failed";
    });

    user.addCase(signinUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isAuth = false;
      state.error = action.payload.message || "Login Failed";
    });

    // Handle logout action
    user.addCase(logout.fulfilled, (state) => {
      state.isAuth = false;
      state.loginDetails = "";
      state.token = "";
      state.stat = {};
    });


    user.addCase(activateUser.pending, (state) => {
      state.isLoading = true;
      state.isAuth = false;
    });

    user.addCase(activateUser.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.success) {
        state.isAuth = true;
        state.loginDetails = action.payload;
        state.token = JSON.stringify(action);
        state.signinResponse = action.payload;
      }
      else {
        state.isAuth = false;
        state.error = action.payload.message || "Login Failed";
      }
    });

    user.addCase(activateUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isAuth = false;
      state.error = action.payload.message || "User Activation Failed";
    });
  },
});

export const { loginPending, loginSuccess, loginFail, logoutSuccess } = loginSlice.actions;
export default loginSlice.reducer;
